<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('用户注销.联系电话')" prop="mobile">
                <a-input v-model="queryParam.mobile" :placeholder="$t('用户注销.请输入联系电话')" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('用户注销.审核状态')" prop="status">
                <a-select
                  :placeholder="$t('用户注销.审核状态')"
                  v-model="queryParam.status"
                  style="width: 100%"
                  allow-clear
                >
                  <a-select-option v-for="(d, index) in dict.type.user_cancel_status" :key="index" :value="d.value">{{
                    d.label
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('用户注销.注销原因')" prop="reason">
                  <a-input v-model="queryParam.reason" :placeholder="$t('用户注销.请输入注销原因')" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('用户注销.审核失败原因')" prop="failReason">
                  <a-input
                    v-model="queryParam.failReason"
                    :placeholder="$t('用户注销.请输入审核失败原因')"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('用户注销.审核人')" prop="examineName">
                  <a-input v-model="queryParam.examineName" :placeholder="$t('用户注销.请输入审核人')" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.重置') }}</a-button
                >
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? $t('通用.收起') : $t('通用.展开') }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:cancel:export']">
          <a-icon type="download" />{{ $t('通用.导出') }}
        </a-button>
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" :statusOptions="dict.type.user_cancel_status" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['user_cancel_status']" :value="record.status" />
        </span>
        <span slot="examineTime" slot-scope="text, record">
          {{ parseTime(record.examineTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['biz:cancel:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:cancel:edit']">
            <a-icon type="edit" />{{$t('通用.修改')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['biz:cancel:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['biz:cancel:remove']"> <a-icon type="delete" />{{$t('通用.删除')}} </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageCancel, listCancel, delCancel } from '@/api/biz/cancel'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Cancel',
  components: {
    CreateForm,
  },
  mixins: [tableMixin],
  dicts: ['user_cancel_status'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        reason: null,
        failReason: null,
        examineName: null,
        examineTime: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '用户昵称',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '联系电话',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '注销原因',
          dataIndex: 'reason',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '审核失败原因',
          dataIndex: 'failReason',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '审核人',
          dataIndex: 'examineName',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '审核时间',
          dataIndex: 'examineTime',
          scopedSlots: { customRender: 'examineTime' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '注销时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
    }
  },
  filters: {},
  created() {
    this.getList()
    this.updateColumns()
  },
  computed: {},
  watch: {
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  methods: {
    /** 查询用户注销列表 */
    getList() {
      this.loading = true
      pageCancel(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        userId: undefined,
        mobile: undefined,
        reason: undefined,
        status: undefined,
        failReason: undefined,
        examineName: undefined,
        examineTime: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delCancel(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'biz/user-cancel/export',
            {
              ...that.queryParam,
            },
            `用户注销_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
    updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
          {
            title: 'User nickname',
            dataIndex: 'userId',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'phone',
            dataIndex: 'mobile',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Reason for cancellation',
            dataIndex: 'reason',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'audit status',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Reasons for audit failure',
            dataIndex: 'failReason',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Auditor',
            dataIndex: 'examineName',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Audit time',
            dataIndex: 'examineTime',
            scopedSlots: { customRender: 'examineTime' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Logout time',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'operation',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
        'zh-CN': [
          {
            title: '用户昵称',
            dataIndex: 'userId',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '联系电话',
            dataIndex: 'mobile',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '注销原因',
            dataIndex: 'reason',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '审核状态',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: '审核失败原因',
            dataIndex: 'failReason',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '审核人',
            dataIndex: 'examineName',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '审核时间',
            dataIndex: 'examineTime',
            scopedSlots: { customRender: 'examineTime' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: '注销时间',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>
